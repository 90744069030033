import React, { useEffect, useState } from 'react';
import { Event } from '@/model/Event';
import { Page } from '@/model/Page';
import { LoaderStatus } from '@/components/Loader';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useDialog } from '@/hooks/useDialog';
import { useForm } from '@/hooks/useForm';
import * as validators from '@/utils/validators';
import { Coupon } from '@/model/Coupon';
import { EventInformation } from '@/model/EventInformation';
import { CustomEventUI } from './ui';
import { getCoupon, pageEvent, saveEventInformation } from '../services';
import { ShouldShowModal } from '../types';

export const CustomEventScreen: React.FC = (): JSX.Element => {
  const [status, setStatus] = useState<LoaderStatus>(LoaderStatus.DEFAULT);
  const [shouldShowModal, setShouldShowModal] = useState<ShouldShowModal>(ShouldShowModal.filter);
  const [page, setPage] = useState<Page<Event, Event>>({
    page: 1,
    pageSize: 50,
  });
  const [events, setEvents] = useState<Event[]>([]);
  const [event, setEvent] = useState<Event>();
  const [coupon, setCoupon] = useState<Coupon>();
  const { title, visible, onChangeTitle, onToggle } = useDialog();

  const handleOnFetchEvents = async (
    newPage: Page<Event, Event>,
    clearEvents: boolean,
  ): Promise<void> => {
    try {
      setStatus(LoaderStatus.LOADING);
      const response = await pageEvent('CUSTOM', newPage);
      setPage(response);
      if (response.list && response.list.length > 0) {
        const newEvents: Event[] = [];
        if (!clearEvents) {
          events.forEach(data => {
            newEvents.push(data);
          });
        }
        response.list.forEach(data => {
          newEvents.push(data);
        });
        setEvents(newEvents);
      }
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    } finally {
      setStatus(LoaderStatus.DEFAULT);
    }
  };

  const {
    formData: formDataFilter,
    formErrors: formErrorsFilter,
    onChangeInput: onChangeInputFilter,
    isFormValid: isFormValidFilter,
    resetForm: resetFormFilter,
  } = useForm<Event>({
    initialData: {
      id: '',
      couponNumber: 0,
      couponValue: 0,
      fileId: '',
      operator: '',
      store: '',
      eventDate: '',
      checkout: '',
      stars: 0,
      saveOccurrence: false,
      deviation: false,
      operationalError: false,
      observation: '',
    },
    validators: {
      eventDate: [validators.required],
    },
  });

  const {
    formData: formDataEventInformation,
    formErrors: formErrorsEventInformation,
    onChangeInput: onChangeInputEventInformation,
    isFormValid: isFormValidEventInformation,
    resetForm: resetFormEventInformation,
  } = useForm<EventInformation>({
    initialData: {
      stars: 0,
      saveOccurrence: false,
      deviation: false,
      operationalError: false,
      observation: '',
    },
  });

  const handleOnShouldShowModal = async ({
    value,
    newTitleModal,
    eventSelected,
  }: {
    value: ShouldShowModal;
    newTitleModal: string | React.ReactNode;
    eventSelected?: Event;
  }): Promise<void> => {
    try {
      setShouldShowModal(value);
      onChangeTitle(newTitleModal);
      if (value === ShouldShowModal.coupon && eventSelected) {
        setEvent(eventSelected);
        setStatus(LoaderStatus.LOADING);
        const response = await getCoupon(eventSelected.id);
        setCoupon(response);

        onChangeInputEventInformation('stars', eventSelected.stars);
        onChangeInputEventInformation('saveOccurrence', eventSelected.saveOccurrence);
        onChangeInputEventInformation('deviation', eventSelected.deviation);
        onChangeInputEventInformation('operationalError', eventSelected.operationalError);
        onChangeInputEventInformation('observation', eventSelected.observation);

        setStatus(LoaderStatus.DEFAULT);
      }
      onToggle();
    } catch (error) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  const handleOnFilter = async (): Promise<void> => {
    if (isFormValidFilter()) {
      onToggle();
      try {
        setStatus(LoaderStatus.LOADING);
        const newPage: Page<Event, Event> = {
          page: 1,
          pageSize: 50,
          entity: formDataFilter,
        };
        const response = await pageEvent('CUSTOM', newPage);
        setPage(response);
        if (response.list && response.list.length > 0) {
          const newEvents: Event[] = [];
          response.list.forEach(data => {
            newEvents.push(data);
          });
          setEvents(newEvents);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };
  const handleOnClearFilter = async (): Promise<void> => {
    onToggle();
    resetFormFilter();
    handleOnFetchEvents(
      {
        page: 1,
        pageSize: 50,
      },
      true,
    );
  };

  const handleOnSaveEventInformation = async (): Promise<void> => {
    if (isFormValidEventInformation() && event) {
      onToggle();
      try {
        setStatus(LoaderStatus.LOADING);
        await saveEventInformation(event.id, formDataEventInformation);
        resetFormEventInformation();
        const newEvents: Event[] = [];
        events.forEach(data => {
          if (data.id !== event.id) {
            newEvents.push(data);
          }
        });
        setEvents(newEvents);
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      } finally {
        setStatus(LoaderStatus.DEFAULT);
      }
    }
  };

  const handleOnGetMoreRecords = async (): Promise<void> => {
    const newPage = {
      ...page,
      page: page.page + 1,
    };
    await handleOnFetchEvents(newPage, false);
  };

  useEffect(() => {
    handleOnFetchEvents(
      {
        page: 1,
        pageSize: 50,
      },
      true,
    );
  }, []);
  return (
    <CustomEventUI
      status={status}
      page={page}
      events={events}
      title={title as string}
      visible={visible}
      onToggle={onToggle}
      shouldShowModal={shouldShowModal}
      formDataFilter={formDataFilter}
      formErrorsFilter={formErrorsFilter}
      onChangeInputFilter={onChangeInputFilter}
      onFilter={handleOnFilter}
      clearFilter={handleOnClearFilter}
      onShouldShowModal={handleOnShouldShowModal}
      event={event}
      coupon={coupon}
      formDataEventInformation={formDataEventInformation}
      formErrorsEventInformation={formErrorsEventInformation}
      onChangeInputEventInformation={onChangeInputEventInformation}
      onSaveEventInformation={handleOnSaveEventInformation}
      onGetMoreRecords={handleOnGetMoreRecords}
    />
  );
};
